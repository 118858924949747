import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Switch from "@mui/material/Switch";
import FormField from "welcome/components/FormField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import SoftInput from "components/SoftInput";

import { ErrorMessage } from "formik";

function Form({form, values, errors, touched, validateForm}) {
  //console.log(form);
  return (
    <SoftBox>
      <SoftTypography variant="h5" fontWeight="regular">
        {form.name}
      </SoftTypography>
      <SoftBox mt={1.625}>
        <Grid container spacing={0}>
          {form.data.map((field, index) =>
            field.state==='active' && field.type !== 'softpull' && field.type !== 'bvr' &&
            (
                <Grid key={index} item xs={12}>
                  <FormField
                    inputType={field.type}
                    placeholder={field.placeholder}
                    options={field.listItems}
                    key={index}
                    type={field.type}
                    label={field.label}
                    name={field.id}
                    error={errors[field.id] && touched[field.id]}
                    values={values}
                    validateForm={validateForm}
                    //placeholder={address2.placeholder}
                  />
                </Grid>
            )
          )}  
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

export default Form;