import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import axios from "axios"

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import SoftInput from "components/SoftInput";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Link from "@mui/material/Link";
import typography from "assets/theme/base/typography";

import { updateContact } from 'api';

function Eligible() {
  const { size } = typography;
  //const [programs, setPrograms] = useState([]);
  const [eligiblePrograms, setEligiblePrograms] = useState([]);
  const [eligiblePage, setEligiblePage] = useState('');
  const [footerPage, setFooterPage] = useState('');
  const [loading, setLoading] = React.useState(true);
  const [submitting, setSubmitting] = React.useState(false);
  const [agree, setAgree] = React.useState(false);
  const [contact, setContact] = useState();
  const [agreeOther, setAgreeOther] = React.useState(false);

  const {state} = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const getPrequal = async function () {
      const url = process.env.REACT_APP_PUBLIC_API;
      const hostname = window.location.host.split('.')[0];
      const tenant = await axios.get(url + "tenant/" + hostname);
      setLoading(true);
      const results = await axios.get(url + "prequal/" + tenant.data.tenantId);
      const prequal = results.data;
      console.log('### prequal')
      console.log(prequal)

      //const programs = (prequal.filter(e=>{return e.sk==='programs'})[0].data)
      //                 .filter(p => p.state==='active');

      //setPrograms(programs);

      if (state?.eligiblePrograms) {
        console.log('### eligiblePrograms');
        console.log(eligiblePrograms);
        setEligiblePrograms(state.eligiblePrograms)
      }

      if (state?.contact) {
        const bizForm = prequal.find(p => p.sk==='forms-business-verification' && p.state==='active');
        if (bizForm) {
          //const fields = bizForm.data;
          //const bvr = fields.find(f => f.type==='bvr');
          //if (bvr) {
            state.contact.data.bvr = '';    
          //}     
        }

        setContact(state.contact)
      }

      if (prequal.filter(e=>{return e.sk==='pages-eligible'}).length==1)
        setEligiblePage(prequal.filter(e=>{return e.sk==='pages-eligible'})[0].data);

      if (prequal.filter(e=>{return e.sk==='pages-footer'}).length==1)
        setFooterPage(prequal.filter(e=>{return e.sk==='pages-footer'})[0].data);

      setLoading(false);  
    }
    getPrequal();
  }, [])


  const handleApply = async (program, doNav) => {
    var contactClone = structuredClone(contact);
    setSubmitting(true);

    if (!contactClone.data.referrals)
      contactClone.data.referrals = [];
    
    const referred = contactClone.data.referrals.filter(r => r.code===program.code).length>0
    if (!referred) {
      program.referralDate = Date.now()
      contactClone.data.referrals.push(program);
      const updatedContact = await updateContact(contactClone);
      setContact(updatedContact);
    }        
    if (doNav)
      //navigate(`/apply`);
      navigate(
        `/apply`,
        {
          state: {
            "program": program
          }
        }
      );
    }

  const handleLocal = async () => {
    const otherPrograms = eligiblePrograms
      .filter(programs => programs.state==='active' && programs.undisclosed);
    for (const otherProgram of otherPrograms) { 
      handleApply(otherProgram, false); 
    }
    navigate(`/apply`);    
  }


  return (

        <>


    <SoftBox
      bgColor="text" 
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      px={4}
      sx={{position: "fixed", top: "0px", zIndex: "999"}}
    >
      <SoftBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="white"
        fontSize={size.sm}
        px={1.5}
      >
        <SoftBox fontSize={size.md} color="white" mb={-0.5} mx={0.5}>
        </SoftBox>
        
        <Link href="https://www.accesscapital.tech" target="_blank" color="white">
          <SoftTypography variant="button" fontWeight="medium" color="white">
          </SoftTypography>
        </Link>
      </SoftBox>
      <SoftBox
        component="ul"
        color="white"
        sx={({ breakpoints }) => ({
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          listStyle: "none",
          mt: 3,
          mb: 0,
          p: 0,

          [breakpoints.up("lg")]: {
            mt: 0,
          },
        })}
      >
    
        <SoftBox px={2} lineHeight={1}>
          <Link href="https://www.accesscapital.tech" target="_blank">
            <SoftTypography variant="button" fontWeight="regular" color="white">
              About ACT
            </SoftTypography>
          </Link>
        </SoftBox>
      </SoftBox>
    </SoftBox>

      <Card>



      {loading &&
        <>
        <Grid container>    
          <Grid item xs={4}>
          {/**
            <SoftBox component="img" src={act_logo} mt={6} ml={16}>
            </SoftBox>
          **/}
          </Grid>
        </Grid>    
        <SoftBox>
          <SoftTypography mt={8} variant="h3" textAlign="center">
            <CircularProgress color="dark"/>
          </SoftTypography>
        </SoftBox>
        </>  
      }   
      {!loading &&
        <SoftBox sx={{ height: '2px;' }}>   
        </SoftBox>  
      }

      {eligiblePrograms.length > 0 &&  
          <SoftBox pt={0} pb={8}>
            <Grid container justifyContent="center">
              <Grid item xs={12} lg={10}>
                <SoftBox mt={6} mb={1}>
                  <div dangerouslySetInnerHTML={{ __html: eligiblePage }} />
                </SoftBox>
                
                {eligiblePrograms.filter(programs => programs.state==='active' && !programs.undisclosed).map((program, index) =>
                  <div key={index}>
                    <SoftBox mt={6} mb={1}>
                      <Grid container justifyContent="center">
                        <SoftTypography variant="h3" fontWeight="bold" color="text">
                          {
                            //program?.name
                          }
                        </SoftTypography>
                      </Grid>  
                    </SoftBox>  
                    <SoftBox mt={2}>
                      <div dangerouslySetInnerHTML={{ __html: program?.summary }} />
                    </SoftBox>
                    <SoftBox mt={4} display="flex">
                      <SoftBox>
                        <Switch
                          value={agree}
                          onChange={() => setAgree(!agree)}
                        />
                      </SoftBox>
                      <SoftBox width="80%" ml={2}>
                        <div dangerouslySetInnerHTML={{ __html: program?.disclaimer }} />
                      </SoftBox>  
                    </SoftBox>  
                    <SoftBox mt={6} mb={12} mr={6}>
                      <Grid container justifyContent="right">
                        <Stack spacing={4} direction="row">
                        {/**    
                          <SoftButton 
                            variant="contained" 
                            color="info"
                          >
                            Maybe Later
                          </SoftButton>
                        **/}  
                          {!submitting &&
                            <SoftButton 
                              variant="contained" 
                              color="info"
                              disabled={!agree}
                              onClick={() => handleApply(program, true)}
                            >
                              {program.cta}
                            </SoftButton>
                          }  
                          {submitting &&
                            <CircularProgress color="info"/>
                          }  
                        </Stack>  
                      </Grid>
                    </SoftBox>
                    <hr/>
                  </div>
                )}
                {/**
                <Grid container justifyContent="center">
                  <SoftTypography mt={3} variant="h3" fontWeight="bold" color="text">
                    Local Lenders
                  </SoftTypography>
                </Grid>     
                <SoftBox mt={6}>
                  <SoftTypography>
                    We work with additional local lenders in your
                    area that provide lending options tailored to your specific needs.
                    Would you like to learn more?  
                  </SoftTypography>
                </SoftBox>
                <SoftBox mt={2} display="flex">
                  <SoftBox>
                    <Switch
                      value={agreeOther}
                      onChange={() => setAgreeOther(!agreeOther)}
                    />  
                  </SoftBox>
                  <SoftBox width="80%" ml={2}>
                    <SoftTypography variant="body2" fontWeight="bold" color="text">
                      Yes, please forward my information to local lenders in my area
                    </SoftTypography>
                  </SoftBox>  
                </SoftBox>  
                <SoftBox mt={2} mb={12} mr={6}>
                  <Grid container justifyContent="right">
                    <Stack spacing={4} direction="row">    
                      {!submitting &&
                        <SoftButton 
                          variant="gradient" 
                          color="info"
                          disabled={!agreeOther}
                          onClick={() => handleLocal()}
                        >
                          Submit
                        </SoftButton>
                      }
                      {submitting &&
                        <CircularProgress color="info"/>
                      }  
                    </Stack>  
                  </Grid>
                </SoftBox>
                **/}
                <hr/>  
                <SoftBox>
                  <div dangerouslySetInnerHTML={{ __html: footerPage }} />
                </SoftBox>
              </Grid>
            </Grid>   
          </SoftBox>
        }


          
      </Card>
    <SoftBox
      sx={{position: "fixed", left: "0", bottom: "0", right: "0"}}
      bgColor="text" 
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      px={4}
    >
      <SoftBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="white"
        fontSize={size.sm}
      >
        Powered by 
        &copy; {new Date().getFullYear()},
        <SoftBox fontSize={size.md} color="white" mb={-0.5} mx={0.5}>
        </SoftBox>
        
        <Link href="https://www.accesscapital.tech" target="_blank" color="white">
          <SoftTypography variant="button" fontWeight="medium" color="white">
            Access Capital Technologies
          </SoftTypography>
        </Link>
      </SoftBox>
      <SoftBox
        component="ul"
        color="white"
        sx={({ breakpoints }) => ({
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          listStyle: "none",
          mt: 3,
          mb: 0,
          p: 0,

          [breakpoints.up("lg")]: {
            mt: 0,
          },
        })}
      >
    
      <SoftBox px={2} lineHeight={1}>
          <Link href="https://www.accesscapital.tech" target="_blank">
            <SoftTypography variant="button" fontWeight="regular" color="white">
              About ACT
            </SoftTypography>
          </Link>
        </SoftBox>
      </SoftBox>


    </SoftBox>

      </>
  );
}

export default Eligible;
