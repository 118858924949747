import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import kountSDK from "@kount/kount-web-client-sdk";
import { v4 as uuidv4 } from "uuid";
import App from "App";

// Soft UI Context Provider
import { SoftUIControllerProvider } from "context";

// const kountConfig = {
//   "clientID": "cun5k8i2guo4h5od87dg",   
//   "environment": process.env.REACT_APP_KOUNT_ENV, 
//   "isSinglePageApp": true,
//   isDebugEnabled: true
// }
// const kountSessionID = uuidv4().replace(/-/g, '');

// console.log('### kountConfig');
// console.log(kountConfig);

// console.log('### kountSessionID');
// console.log(kountSessionID);

// kountSDK(kountConfig, kountSessionID);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <SoftUIControllerProvider>
      <App />
    </SoftUIControllerProvider>
  </BrowserRouter>
);
