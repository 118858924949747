import axios from "axios"

export const updateContact = async (contact) => {
  console.log('### updateContact');
  console.log(contact);
  const url = process.env.REACT_APP_PUBLIC_API + "/contact";
  const results = await axios.put(url, contact);
  return results.data;
};

export const getPostUrl = async (payload) => {
  const url = process.env.REACT_APP_PUBLIC_API + "/postUrl";
  const results = await axios.post(url, payload);
  //console.log(results)
  return results.data;
}

export const postS3 = async (url, payload) => {
    return await axios.post(url, payload, {headers: {
        "Content-Type": "multipart/form-data",
    }});
}

export const extract = async (payload) => {
  const url = process.env.REACT_APP_PUBLIC_API + "/ide";
  const results = await axios.post(url, payload);
  return results.data;
}

